import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import _ from 'lodash';

export const ANALYZED = 'analyzed';
export const BLOCKED = 'attack';
export const SUSPECT = 'suspect';
export const ROBOT = 'robot';

const baseChart: any = {
  accessibility: {
    enabled: false,
  },
  noData: {
    style: {
      fontWeight: 'normal',
      fontSize: '14px',
      color: 'rgb(34, 34, 34)',
    },
  },
  responsive: {
    rules: [
      {
        condition: {
          maxWidth: 200,
        },
        chartOptions: {
          legend: {
            enabled: false,
          },
        },
      },
    ],
  },
  legend: {
    verticalAlign: 'middle',
    align: 'right',
    width: '35%',
    padding: 45,
    labelFormatter: function () {
      return this.name + ' (' + this.percentage.toFixed(2) + '%)';
    },
  },
  chart: {
    plotBackgroundColor: null,
    plotBorderWidth: 0,
    plotShadow: false,
    borderRadius: 5,
    // cursor: 'default',
  },
  credits: {
    enabled: false,
  },
  tooltip: {
    pointFormat: '{series.name}: <b>{point.y}</b>',
  },
  plotOptions: {
    pie: {
      size: '100%',
      showInLegend: true,
      dataLabels: {
        enabled: false,
        style: {
          fontWeight: 'bold',
        },
      },
      // center: ['50%', '50%'],
      point: { events: { click: null } },
      states: {
        hover: {
          halo: {
            size: 0,
          },
          shadow: false,
        },
      },
    },
    series: {
      // cursor: 'default !important',
      stickyTracking: false,
    },
  },
  exporting: {
    enabled: true,
    buttons: {
      contextButton: {
        menuItems: [
          'printChart',
          'separator',
          'downloadPNG',
          'downloadJPEG',
          'downloadSVG',
          'separator',
          'downloadCSV',
          'downloadXLS',
        ],
      },
    },
  },
};

@Injectable()
export class Donut {
  constructor(private translate: TranslateService) {}

  getBaseChart() {
    return JSON.parse(JSON.stringify(baseChart));
  }

  formatRepartition(bySite: { [site: string]: number }, slug: DonutType) {
    const total = _.sum(Object.values(bySite));
    const transformedData = _.toPairs(bySite).map(([site, value]) => ({
      name: site == 'other' ? this.translate.instant('Others') : site,
      y: Number(((value / total) * 100).toFixed(2)),
      requests: value,
    }));

    let { chart, exporting, tooltip, legend, plotOptions, ...restBaseChart } = this.getBaseChart();
    tooltip = {
      pointFormat: `{series.name}: <b>{point.percentage:.1f}%</b> ({point.requests} ${this.translate.instant('reqs')})`,
      outside: true,
      positioner: function (labelWidth, labelHeight, point) {
        var chart = this.chart;
        return {
          x: point.plotX + chart.plotLeft - labelWidth - 10, // Mettre le tooltip à gauche du point
          y: point.plotY + chart.plotTop - labelHeight / 2,
        };
      },
    };
    chart.backgroundColor = 'rgba(0,0,0,0)';
    chart.height = '100px';
    plotOptions.pie.size = '80px';
    plotOptions.pie.borderRadius = 0;
    plotOptions.pie.shadow = {
      width: '1',
      opacity: 0.05,
    };

    return {
      ...restBaseChart,
      chart,
      tooltip,
      plotOptions,
      exporting: { enabled: false },
      legend: { enabled: false },
      series: [
        {
          colors: this.palettes[slug],
          type: 'pie',
          name: this.translate.instant('requests'),
          innerSize: '50%',
          data: transformedData,
          tooltip: {
            valueSuffix: '% ',
          },
        },
      ],
    };
  }

  private palettes: { [key in DonutType]: string[] } = {
    analyzed: ['#2ED8B6', '#59E0C1', '#84E9CC', '#09CFAB', '#00B89A'],
    suspect: ['#F7A35C', '#F8B27D', '#FAC19D', '#F6923B', '#F4811A'],
    robot: ['#FED330', '#FEDD59', '#FEE783', '#FEC907', '#FEB201'],
    attack: ['#FF5370', '#FF7A8F', '#FFA1AF', '#FF293F', '#FF001F'],
  };

  formatTypesAttacks(arr) {
    let data = arr.filter((d) => d.id);
    let total = data.reduce((acc, item) => acc + item.y, 0);

    data = Object.values(
      data.reduce((acc, item) => {
        acc[item.id] = acc[item.id] || { ...item, y: 0 };
        acc[item.id].y += item.y;
        return acc;
      }, {}),
    ).map((item: any) => ({ ...item, y: Number(((item.y / total) * 100).toFixed(2)) }));

    const { exporting, ...restBaseChart } = this.getBaseChart();
    if (!data.length) exporting.enabled = false;

    return {
      ...restBaseChart,
      exporting,
      series: [
        {
          type: 'pie',
          name: this.translate.instant('Bloquees'),
          innerSize: '50%',
          size: '75%',
          data,
          tooltip: {
            valueSuffix: '% ',
          },
        },
      ],
    };
  }
}

export const DonutTypes = [BLOCKED, ROBOT, SUSPECT, ANALYZED] as const;
export type DonutType = (typeof DonutTypes)[number];
