import { FilterDataInNotIn, FilterDataLteGte } from './my-logs-filters.component';
import { FACTOR_FILTER_MAP, LogFiltersType } from '../../../services/logs.service';

export enum FilterType {
  SINGLE_INPUT = 'singleInput',
  MULTI_IN_NOTIN = 'multipleInNotIn',
  INTERVAL = 'interval',
}

export enum FiltersEnum {
  IP = 'ip',
  STATUS_CODE = 'statusCode',
  ACTION = 'action',
  CAUSE = 'cause',
  COUNTRY = 'country',
  PATH = 'path',
  PATH_STARTS_WITH = 'path.startsWith',
  RESPONSE_TIME_GTE = 'responseTime.gte',
  CONTENT_LENGTH_GTE = 'contentLength.gte',
  CACHE_STATUS = 'cacheStatus',
  METHOD = 'method',
  ATTACK_CATEGORY = 'attackCategory',
  CREDIBILITY_BETWEEN = 'credibility',
}

export interface Filter {
  key: FiltersEnum;
  slug: string;
  type: FilterType;
  tooltip?: string;
  active: boolean;
  choices?: Array<any>;
  showDropdown?: boolean;
  canAddChoice?: boolean;
  suffix?: string;
  data: FilterDataInNotIn | FilterDataLteGte | number | string;
}

export function isMultiInNotInFilter(type: FilterType): boolean {
  return type == FilterType.MULTI_IN_NOTIN;
}

export function isSingleInputFilter(type: FilterType): boolean {
  return type == FilterType.SINGLE_INPUT;
}

export function isIntervalFilter(type: FilterType): boolean {
  return type == FilterType.INTERVAL;
}

export function formatFiltersLogs(filters: Filter[]): { [prop: string]: any } {
  const data = {};

  filters.forEach((f) => {
    if (isMultiInNotInFilter(f.type)) {
      if (f.key == FiltersEnum.PATH_STARTS_WITH) {
        // because laurent didn't want pathStartsWith.in and pathStartsWith.notIn
        data['path.startWith'] = f.data[LogFiltersType.IN].map((d) => d.value);
        data['path.notStartWith'] = f.data[LogFiltersType.NOTIN].map((d) => d.value);
        return;
      }
      data[`${f.key}.${LogFiltersType.IN}`] = f.data[LogFiltersType.IN].map((d) => d.value);
      data[`${f.key}.${LogFiltersType.NOTIN}`] = f.data[LogFiltersType.NOTIN].map((d) => d.value);
    } else if (isIntervalFilter(f.type)) {
      data[`${f.key}.${LogFiltersType.GTE}`] = f.data[LogFiltersType.GTE] * (FACTOR_FILTER_MAP[f.key] || 1);
      data[`${f.key}.${LogFiltersType.LTE}`] = f.data[LogFiltersType.LTE] * (FACTOR_FILTER_MAP[f.key] || 1);
    } else if (isSingleInputFilter(f.type)) {
      data[f.key] = f.data;
    }
  });

  return data;
}
