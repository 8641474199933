import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { UrlStats } from 'app/services/logs.service';
import { RESPONSE_TIME } from 'app/shared/highcharts/graph/graph';
import { TrackEvent } from 'app/theme/dashboard/track.event';
import { FiltersEnum } from '../../my-logs/filters/filters';

@Component({
  selector: 'app-slow-requests-table',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './slow-requests-table.component.html',
  styleUrl: './slow-requests-table.component.scss',
})
export class SlowRequestsTableComponent {
  @Input() slowRequests: UrlStats[];
  @Input() print: boolean = false;

  @Output() clickPath = new EventEmitter<TrackEvent>();

  constructor(public translate: TranslateService) {}

  track(row) {
    const { avg, path, site, ..._ } = row;

    return this.clickPath.emit({
      [FiltersEnum.RESPONSE_TIME_GTE]: Math.trunc(avg),
      [FiltersEnum.PATH]: path,
      graphType: RESPONSE_TIME,
      site,
    });
  }
}
