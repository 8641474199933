import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Global } from 'app/global';
import { computeInterval } from 'app/shared/highcharts/graph/graph';
import { SiteMode } from 'app/shared/hostMode/hostMode.service';
import { duration } from 'app/shared/utils/date-range';
import _ from 'lodash';
import moment, { Moment } from 'moment';
import { firstValueFrom } from 'rxjs';
import { DATE_NOW, DATE_TODAY } from '../shared/date-range-selector/calendar-data.service';
import { EPOCH_MILLI_4_HOURS, sortAlphabetically } from '../shared/utils/data-utils';
import { AuthService, CLUSTER_DELEGATION_ADMIN } from './auth.service';

import { Filter } from '../theme/my-logs/filters/filters';

export const CONTEXT = 'context';
export const ADMIN_CONTEXT = 'adminClusterContext';
export const JOURNAL_CONTEXT = 'journalContext';

export type ContextEnum = 'context' | 'adminClusterContext';

interface Cluster {
  id: number;
  name: string;
  clusterId: string;
}

export interface Site {
  id: number;
  name: string;
  tags: string[];
  mode: SiteMode;
  cluster: Cluster;
}

interface Period {
  start: Moment;
  end: Moment;
  autoRefresh: boolean;
  recomputeEndDateToNow: boolean;
}

export interface JournalFilters {
  period: Period;
  type?: string;
  severity?: number;
  search: string;
  id: string;
}

class Context {
  isLoaded: boolean;
  store: {
    sitesList: Site[]; // global sites list
    sites: Site[]; // sites list after filters applied
    clusters: Cluster[]; // global clusters list
    tags: string[]; // global tags list
  };
  current: {
    period: Period;
    selectedSites: Site[]; // selected sites
    cluster: Cluster; // selected cluster
    tag?: string; // selected tag
    journalFilters?: JournalFilters; // filter object for Journal page
    trackingFilters?: Filter[]; // filter object for My Logs
  };

  constructor() {
    this.isLoaded = false;
    this.store = {
      sitesList: [], // global sites list
      sites: [], // sites list after filters applied
      clusters: [], // global clusters list
      tags: [], // global tags list
    };
    this.current = {
      period: {
        start: DATE_TODAY(), // default is last week
        end: DATE_NOW(),
        autoRefresh: true,
        recomputeEndDateToNow: true,
      },
      selectedSites: [],
      cluster: null, // selected cluster
      tag: null, // selected tag
      journalFilters: null, // filter object for Journal page
      trackingFilters: null, // filter object for My Logs
    };
  }
  buildRequestData(): BaseLogRequest {
    let current = this.current;
    return {
      clusterId: current.cluster?.id,
      tags: current.tag ? [current.tag] : null,
      sites: current.selectedSites.map((s) => s.name),
      beginDate: current.period.start,
      endDate: current.period.end,
    };
  }

  buildRequestDataForDateHistogram(): any {
    return {
      ...this.buildRequestData(),
      aggregationTimeBound: `${computeInterval(this.current.period)}s`,
    };
  }

  refreshPeriod() {
    const period = this.current.period;
    if (period.recomputeEndDateToNow) {
      const interval = duration(period);
      period.end = DATE_NOW();
      period.start = moment(this.current.period.end).subtract(interval);
    }
  }

  selectClusterByClusterId(clusterId: string) {
    this.current.cluster = this.store.clusters.find((c) => c.clusterId == clusterId);
    if (!this.current.cluster) console.error(`Cannot select cluster; cluster not found: clusterId=${clusterId}`);
  }
}

export interface BaseLogRequest {
  clusterId: number;
  tags?: string[];
  sites?: string[];
  beginDate: Moment;
  endDate: Moment;
}

@Injectable({
  providedIn: 'root',
})
export class SitesService {
  context: Context = new Context();
  adminClusterContext: Context = new Context();

  constructor(
    private http: HttpClient,
    private auth: AuthService,
  ) {}

  async load(ctx: ContextEnum) {
    if (this[ctx].isLoaded) return;

    const data: any = ctx == ADMIN_CONTEXT ? await this.loadSitesByCluster() : await this.loadSites();

    this[ctx].store.tags = await this.loadTags();
    this[ctx].store.sites = data.sites;
    this[ctx].store.sitesList = data.sites;
    this[ctx].store.clusters =
      ctx == ADMIN_CONTEXT
        ? data.clusters.filter((c) => (c.role ?? c.accessRight) == CLUSTER_DELEGATION_ADMIN)
        : data.clusters;
    this[ctx].current.cluster = this[ctx].store.clusters.at(0);

    this.computeSiteList(ctx);
    this[ctx].isLoaded = true;
  }

  async loadSitesByCluster(): Promise<{ sites: Site[]; clusters: Cluster[] }> {
    const clusters: any = await this.loadClusters();
    const sites = _.flatten(
      await Promise.all(
        _(clusters)
          .filter((c) => c.role == CLUSTER_DELEGATION_ADMIN)
          .map((c) => this.getClusterSites(c.cluster))
          .value(),
      ),
    );

    return {
      sites,
      clusters: clusters.map((c: any) => ({ ...c.cluster, role: c.role })),
    };
  }

  async getClusterSites(cluster: Cluster) {
    return (
      await firstValueFrom(this.http.get<any[]>(this.currentOrganizationUrl() + `/clusters/${cluster.id}/sites/name`))
    )
      .sort((a, b) => b.name - a.name)
      .map((site) => ({
        ...site,
        cluster,
      }));
  }

  async loadSites(): Promise<{ sites: Site[]; clusters: Cluster[] }> {
    const sites: any = await firstValueFrom(this.http.get<any[]>(this.currentOrganizationUrl() + '/sites/name'));
    const clusters = _(sites)
      .map((site) => site.cluster)
      .uniqBy((c) => c.id)
      .value();

    return { sites: sites.sort((a, b) => sortAlphabetically(a.name, b.name)), clusters };
  }

  async loadClusters() {
    return await this.auth.getOrganizationClusters(this.auth.currentOrganization.id);
  }

  computeSiteList(ctx: ContextEnum) {
    // apply filters to sites list
    this[ctx].current.selectedSites = [];

    this[ctx].store.sites = this[ctx].store.sitesList.filter(
      (site) =>
        (!this[ctx].current.cluster || site.cluster.clusterId == this[ctx].current.cluster.clusterId) &&
        (!this[ctx].current.tag || site.tags.some((tag) => tag == this[ctx].current.tag)),
    );
  }

  async loadTags(): Promise<string[]> {
    return (await firstValueFrom(this.http.get<string[]>(this.currentOrganizationUrl() + '/tags'))).sort((a, b) =>
      a.toLowerCase().localeCompare(b.toLowerCase()),
    );
  }

  async createTags(hotes, tags: string[]) {
    let datas = [];
    hotes.forEach((h) => {
      tags.forEach((t) => datas.push({ tag: t, hoteId: h.id }));
    });

    return await firstValueFrom(this.http.post(Global.baseUrl + 'tags/create', { datas }));
  }

  resetContext(ctx, clusterName) {
    this[ctx].current.tag = null;
    this[ctx].store.sites = this[ctx].store.sitesList;
    this[ctx].current.cluster = this[ctx].store.clusters.find((c) => c.name == clusterName);
  }

  purgeContext() {
    this.context = new Context();
    this.adminClusterContext = new Context();
  }

  showAutoRefresh(ctx) {
    return (
      this[ctx].current.period.recomputeEndDateToNow &&
      moment(this[ctx].current.period.end).diff(this[ctx].current.period.start.valueOf()) <= EPOCH_MILLI_4_HOURS + 1
    );
  }

  setSite(ctx: ContextEnum, name: string) {
    const site = this[ctx].store.sites.find((h) => h.name == name);
    this[ctx].current.selectedSites = [site];
    return site;
  }

  currentOrganizationUrl(): string {
    return Global.baseUrl + 'v2/organizations/' + this.auth.currentOrganization.id;
  }

  async getAccessRights(siteName): Promise<string[]> {
    return firstValueFrom(this.http.get<string[]>(this.currentOrganizationUrl() + `/sites/${siteName}/access-rights`));
  }
}
