<div class="mt-3 flex-align-items wrap logs-filters g-10">
  <button (click)="toggleNewFilter($event)" [disabled]="isLoading" class="btn btn-sm btn-primary">
    <i class="icofont icofont-plus mr-1"></i>{{ 'AddFilter' | translate }}
  </button>
  <button
    [title]="(hasFilterActive ? 'DisableAllFilters' : 'EnableAllFilters') | translate"
    (click)="hasFilterActive ? disableAllFilters() : enableAllFilters()"
    [disabled]="!filters.length || isLoading"
    class="disable-all btn btn-sm btn-outline-primary d-flex align-items-center justify-content-center"
  >
    <i class="icofont icofont-eye{{ hasFilterActive ? '-blocked' : '' }}"></i>
  </button>
  <div *ngFor="let filter of filters" class="filter">
    <button
      (click)="openEditFilterMenu($event, filter)"
      class="btn btn-sm btn-outline-primary flex-align-items filter-btn"
      [ngStyle]="{ opacity: filter.active && !isLoading ? 1 : 0.5 }"
      [disabled]="isLoading"
    >
      <i *ngIf="!filter.active" class="icofont icofont-eye-blocked"></i>
      <b>{{ filter.slug }}</b
      >{{ lang == 'fr' ? '&nbsp;' : '' }}:
      <ng-container [ngSwitch]="filter.type">
        <ng-container *ngSwitchCase="FilterType.MULTI_IN_NOTIN">
          <i *ngIf="filter.data[LogFiltersType.IN]?.length" class="ml-1 icofont icofont-plus-circle"></i>
          {{ formatValuesToPrint(filter.data[LogFiltersType.IN]) }}
          <i *ngIf="filter.data[LogFiltersType.NOTIN]?.length" class="ml-1 icofont icofont-minus-circle"></i>
          {{ formatValuesToPrint(filter.data[LogFiltersType.NOTIN]) }}
        </ng-container>
        <ng-container *ngSwitchCase="FilterType.SINGLE_INPUT">
          {{ filter.data }}&nbsp;{{ filter?.suffix }}
        </ng-container>
        <ng-container *ngSwitchCase="FilterType.INTERVAL">
          {{ filter.data[LogFiltersType.GTE] }} {{ 'and' | translate }} {{ filter.data[LogFiltersType.LTE] }}
          {{ filter?.suffix }}
        </ng-container>
      </ng-container>
      <div class="delete-filter">
        <i (click)="deleteFilter(filter, $event)" class="ml-1 icofont icofont-close"></i>
      </div>
    </button>
  </div>
</div>

<app-tooltip-menu #tooltipFilterMenu>
  <div class="py-2">
    <div (click)="toggleEditFilter($event)" class="tooltip-item">
      <i class="icofont icofont-edit-alt"></i>
      <span>{{ 'Edit' | translate }}</span>
    </div>
    <div
      *ngIf="currentFilter?.active && currentFilter.type == FilterType.MULTI_IN_NOTIN"
      (click)="invertInNotInEvent(currentFilter, $event)"
      class="tooltip-item"
    >
      <i class="icon-arrows-horizontal"></i>
      <span>{{ 'Invert' | translate }}</span>
    </div>
    <div *ngIf="currentFilter?.active" (click)="toggleActiveFilter(currentFilter, $event)" class="tooltip-item">
      <i class="icofont icofont-eye-blocked"></i>
      <span>{{ 'TemporarilyDisable' | translate }}</span>
    </div>
    <div *ngIf="!currentFilter?.active" (click)="toggleActiveFilter(currentFilter, $event)" class="tooltip-item">
      <i class="icofont icofont-eye"></i>
      <span>{{ 'Reenable' | translate }}</span>
    </div>
    <div (click)="deleteFilter(currentFilter, $event)" class="tooltip-item">
      <i class="icofont icofont-ui-delete"></i>
      <span>{{ 'supprimer' | translate }}</span>
    </div>
  </div>
</app-tooltip-menu>

<app-tooltip-menu #tooltipNewFilter class="tooltip-new-filter">
  <h6>{{ (mode === Mode.ADD ? 'NewFilter' : 'EditFilter') | translate }}</h6>
  <div class="flex-align-items">
    <div
      class="logs-new-filter flex-align-items p-2 g-5 {{
        currentFilter?.type == FilterType.SINGLE_INPUT ? 'pr-0' : ''
      }}"
    >
      <div class="form-group mr-3 mb-0">
        <label for="">{{ 'FilterType' | translate }}</label>
        <select
          [(ngModel)]="selectedFilterSlug"
          [disabled]="mode === Mode.EDIT"
          (change)="onNewFilterFieldChange()"
          class="form-control"
        >
          <option value="">{{ 'FilterType' | translate }}</option>
          <option *ngFor="let f of availablesFilters" [value]="f.slug">
            {{ f.slug }}
          </option>
        </select>
      </div>
      <ng-container *ngIf="currentFilter" [ngSwitch]="currentFilter?.type">
        <ng-container *ngSwitchCase="FilterType.MULTI_IN_NOTIN">
          <div class="form-group mb-0 mr-2">
            <label for="">{{ 'ValuesToInclude' | translate }}</label>
            <ng-select
              class="custom-scrollbar no-arrow"
              [ngClass]="{ 'large-dropdown': isLargeChoicesDropdown }"
              [(ngModel)]="currentFilter.data[LogFiltersType.IN]"
              [closeOnSelect]="false"
              [clearSearchOnAdd]="true"
              [isOpen]="currentFilter?.showDropdown != null ? currentFilter.showDropdown : null"
              [notFoundText]="currentFilter.canAddChoice ? translate.instant('AddValues') : translate.instant('NoData')"
              [addTagText]="translate.instant('ajouter')"
              [searchable]="true"
              [items]="currentFilter?.choices"
              bindLabel="slug"
              [addTag]="currentFilter.canAddChoice ? validateField : null"
              [multiple]="true"
              [clearable]="true"
              (paste)="handlePaste($event, currentFilter, LogFiltersType.IN)"
            >
              <ng-template ng-label-tmp let-item="item" let-clear="clear">
                <span class="ng-value-icon left" (click)="clear(item)">×</span>
                <span class="ng-value-label" [title]="item.slug">{{ item.slug }}</span>
              </ng-template>
            </ng-select>
          </div>
          <button
            [title]="'Invert' | translate"
            (click)="invertInNotIn(currentFilter)"
            class="intervert btn btn-sm btn-outline-primary d-flex align-items-center justify-content-center mr-2"
          >
            <i class="icon-arrows-horizontal"></i>
          </button>
          <div class="form-group mb-0">
            <label for="">{{ 'ValuesToExclude' | translate }}</label>
            <ng-select
              class="selector custom-scrollbar no-arrow"
              [ngClass]="{ 'large-dropdown': isLargeChoicesDropdown }"
              [(ngModel)]="currentFilter.data[LogFiltersType.NOTIN]"
              [closeOnSelect]="false"
              [clearSearchOnAdd]="true"
              [notFoundText]="currentFilter.canAddChoice ? translate.instant('AddValues') : translate.instant('NoData')"
              [addTagText]="translate.instant('ajouter')"
              [searchable]="true"
              [items]="currentFilter?.choices"
              bindLabel="slug"
              [addTag]="currentFilter.canAddChoice ? validateField : null"
              [multiple]="true"
              [clearable]="true"
              (paste)="handlePaste($event, currentFilter, LogFiltersType.NOTIN)"
            >
              <ng-template ng-label-tmp let-item="item" let-clear="clear">
                <span class="ng-value-icon left" (click)="clear(item)">×</span>
                <span class="ng-value-label" [title]="item.slug">{{ item.slug }}</span>
              </ng-template>
            </ng-select>
          </div>
        </ng-container>
        <ng-container *ngSwitchCase="FilterType.SINGLE_INPUT">
          <div>
            <label for="">{{ 'Values' | translate }}</label>
            <input
              [(ngModel)]="currentFilter.data"
              class="form-control [&::-webkit-inner-spin-button]:appearance-none"
            />
          </div>
        </ng-container>
        <ng-container *ngSwitchCase="FilterType.INTERVAL">
          <div class="form-group mb-0">
            <label for="">{{ 'From...' | translate }}</label>
            <input
              [(ngModel)]="currentFilter.data[LogFiltersType.GTE]"
              class="form-control [&::-webkit-inner-spin-button]:appearance-none"
            />
          </div>
          <i class="arrow-between icofont icofont-long-arrow-right"></i>
          <div class="form-group mb-0">
            <label for="">{{ 'to' | translate }}</label>
            <input
              [(ngModel)]="currentFilter.data[LogFiltersType.LTE]"
              class="form-control [&::-webkit-inner-spin-button]:appearance-none"
            />
          </div>
        </ng-container>
      </ng-container>
    </div>
    <div *ngIf="currentFilter?.suffix" class="new-filter-suffix">{{ currentFilter.suffix }}</div>
  </div>
  <div *ngIf="currentFilter?.tooltip" class="new-filter-tooltip bg-blue m-2 mt-0">
    <small [innerHTML]="currentFilter?.tooltip"></small>
  </div>
  <div class="flex-align-items pull-right g-10 p-2">
    <button (click)="cancelAddEditFilter($event)" class="btn btn-sm btn-outline-danger">
      {{ 'Annuler' | translate }}
    </button>
    <button
      (click)="mode === Mode.ADD ? addFilter($event) : editFilter($event)"
      [disabled]="!canAddEditFilter"
      class="btn btn-sm btn-primary"
    >
      {{ (mode === Mode.ADD ? 'Ajouter' : 'Edit') | translate }}
    </button>
  </div>
</app-tooltip-menu>
